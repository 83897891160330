import Home from './pages/Home'
import './App.css';


function App() {
  return (
    <div className='bg-main-bg'>
      <Home />
    </div>
  );
}

export default App;