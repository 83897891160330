import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';

const YearlyRevenue = () => {

    const [before, setBefore] = useState([]);
    const [current, setCurrent] = useState([]);
    const [per, setPer] = useState([]);
    const { date } = useStateContext()

    const getData = async () => {
        let dates = new Date(date)
        const { data } = await axios.post(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/current-year`,
            { date: dates }
        );
        setCurrent(data);
    };
    const getPrevData = async () => {
        let dates = new Date(date)
        const { data } = await axios.post(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/year-before`,
            { date: dates }
        );
        setBefore(data);
    };

    const calculate = async () => {
        let dates = new Date(date)
        const { data } = await axios.post(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/per`,
            { date: dates }
        );
        setPer(data);
    }

    useEffect(() => {
        getData()
        getPrevData()
        calculate()
    }, [date])


    return (
        <div>
            <div>
                <div className='flex justify-center gap-1 m-7 flex-col'>
                    <p className='text-zinc-600 text-3xl font-semibold scale'>{current} $</p>
                    <p className='text-zinc-400 text-sm'>CURRENT PERIOD  <span className={per < 0 ? 'text-red-500 bg-slate-200 p-1 rounded-md' : 'text-lime-500 bg-slate-200 p-1 rounded-md'}>{per}%</span></p>
                </div>
                <hr className='ml-7 mr-7' />
                <div className='flex justify-center gap-1 m-7 flex-col'>
                    <p className='text-zinc-600 text-3xl font-semibold scale'>{before} $</p>
                    <p className='text-zinc-400 text-sm'>PREVIOUS PERIOD</p>
                </div>
            </div>
        </div>
    )
}

export default YearlyRevenue