import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { ChartComponent, SeriesCollectionDirective, Inject, SeriesDirective, Legend, Tooltip, Highlight, Category, StackingLineSeries } from '@syncfusion/ej2-react-charts'
import { useStateContext } from '../contexts/ContextProvider';


const SalesGrowthPerMonth = () => {

    const [data, setData] = useState([]);
    const { date } = useStateContext()

    let date1 = new Date(date);


    const data1 = [
        { x: "Jan", y: 0 }, 
        { x: "Feb", y: data.feb }, 
        { x: "Mar", y: data.mar }, 
        { x: "Apr", y: data.apr }, 
        { x: "May", y: data.may }, 
        { x: "Jun", y: data.jun },
        { x: "Jul", y: data.jul }, 
        { x: "Aug", y: data.aug }, 
        { x: "Sep", y: data.sep },
        { x: "Oct", y: data.oct },
        { x: "Nov", y: data.nov },
        { x: "Dec", y: data.dec },
    ];

    const getData = async () => {
        let dates = new Date(date)
        const { data } = await axios.post(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/sales-growth-per-month`,
            { date: dates }
        );
        setData(data);
    };

    useEffect(() => {
        getData()
    }, [date])

    return (
        <div>
            <div>
                <p className='font-bold text-zinc-600'>Sales growth per month</p>
                <div className='flex justify-center gap-1 m-7'>
                <ChartComponent id='charts11' style={{ textAlign: "center" }}
                primaryXAxis={{ majorGridLines: { width: 0 }, minorGridLines: { width: 0 }, majorTickLines: { width: 0 }, minorTickLines: { width: 0 }, interval: 1, lineStyle: { width: 0 }, valueType: 'Category'  }}
                primaryYAxis={{ lineStyle: { width: 0 }, minimum: -100, maximum: 100, interval: 20, majorTickLines: { width: 0 }, majorGridLines: { width: 1 }, minorGridLines: { width: 1 }, minorTickLines: { width: 0 }, labelFormat: '{value}%', }}
                legendSettings={{ enableHighlight: true }}
                chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }}>
                <Inject services={[StackingLineSeries, Category, Legend, Tooltip, Highlight]} />
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={data1} xName='x' yName='y' width={2} type='StackingLine' marker={{ isFilled: true, visible: true, shape: 'Circle', width: 7, height: 7 }} >
                    </SeriesDirective>
                </SeriesCollectionDirective>
            </ChartComponent>
                </div>
            </div>
        </div>
    )
}

export default SalesGrowthPerMonth