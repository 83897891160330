import React from 'react'
import Select from '../components/Select'
import OverallSalesData from '../components/OverallSalesData'
import NewUsers from '../components/NewUsers'
import SalesGrowth from '../components/SalesGrowth'
import YearlyRevenue from '../components/YearlyRevenue'
import SalesVsTarger from '../components/SalesVsTarger'
import TotalSiteRevenue from '../components/TotalSiteRevenue'
import Customers from '../components/Customers'
import RevenuePerProduct from '../components/RevenuePerProduct'
import SalesGrowthPerMonth from '../components/SalesGrowthPerMonth'
import { motion } from "framer-motion"

const Home = () => {

  
  return (
    <motion.div initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01]
    }}>
      <div className='p-9'>
        <h3 className='font-bold text-3xl text-slate-700'>Executive Dashboard</h3>
      </div>
      <div className='flex flex-wrap gap-1'>
        <div className='m-9 mt-0 p-4 rounded-md bg-white w-400 '>
          <Select />
        </div>
      </div>
      <div className='flex justify-between'>
        <div className='m-9 p-4 rounded-md bg-white w-full hover-box'>
          <NewUsers />
        </div>
        <div className='m-9 p-4 rounded-md bg-white w-full hover-box'>
          <OverallSalesData />
        </div>
        <div className='m-9 p-4 rounded-md bg-white w-full hover-box'>
          <SalesGrowth />
        </div>
        <div className='m-9 p-4 rounded-md bg-white w-full hover-box'>
          <YearlyRevenue />
        </div>
      </div>
      <div className='flex justify-between'>
        <div className='m-9 p-4 rounded-md bg-white w-full hover-box'>
          <SalesVsTarger width="100%" heigth="400px" />
        </div>
        <div className='m-9 p-4 rounded-md bg-white w-full hover-box'>
          <SalesGrowthPerMonth />
        </div>
      </div>
      <div className='flex justify-between'>
        <div className='m-3 p-4 rounded-md bg-white w-full hover-box'>
          <TotalSiteRevenue />
        </div>
        <div className='m-3 p-4 rounded-md bg-white w-full hover-box'>
          <RevenuePerProduct />
        </div>
        <div className='m-3 p-4 rounded-md bg-white w-full hover-box'>
          <Customers />
        </div>
      </div>
    </motion.div>
  )
}

export default Home