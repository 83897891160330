import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip } from '@syncfusion/ej2-react-charts/src'

const SalesVsTarger = ({ width, heigth }) => {

    const [jan, setJan] = useState([]);
    const { date } = useStateContext()

    const stackedChartData = [
        [
            { x: 'Jan', y: jan.jan },
            { x: 'Feb', y: jan.feb },
            { x: 'Mar', y: jan.mar },
            { x: 'Apr', y: jan.apr },
            { x: 'May', y: jan.may },
            { x: 'Jun', y: jan.jun },
            { x: 'Jul', y: jan.jul },
            { x: 'Aug', y: jan.aug },
            { x: 'Sep', y: jan.sep },
            { x: 'Oct', y: jan.oct },
            { x: 'Nov', y: jan.nov },
            { x: 'Dec', y: jan.dec },
        ],
        [
            { x: 'Jan', y: 111.1 },
            { x: 'Feb', y: 127.3 },
            { x: 'Mar', y: 143.4 },
            { x: 'Apr', y: 159.9 },
            { x: 'May', y: 173.9 },
            { x: 'Jun', y: 210.9 },
            { x: 'Jul', y: 370.2 },
            { x: 'Aug', y: 560.9 },
            { x: 'Sep', y: 1159.9 },
            { x: 'Oct', y: 1236.7 },
            { x: 'Nov', y: 2000.9 },
            { x: 'Dec', y: 2359.4 },
        ],
    ];

    const stackedCustomSeries = [

        {
            dataSource: stackedChartData[0],
            xName: 'x',
            yName: 'y',
            name: 'Sales revenue',
            type: 'StackingColumn',
            background: 'blue',

        },

        {
            dataSource: stackedChartData[1],
            xName: 'x',
            yName: 'y',
            name: 'Target revenue',
            type: 'StackingColumn',
            background: 'red',

        },

    ];

    const stackedPrimaryXAxis = {
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        interval: 1,
        lineStyle: { width: 0 },
        labelIntersectAction: 'Rotate45',
        valueType: 'Category',
    };

    const stackedPrimaryYAxis = {
        lineStyle: { width: 0 },
        minimum: 0,
        maximum: 7000,
        interval: 1000,
        majorTickLines: { width: 0 },
        majorGridLines: { width: 1 },
        minorGridLines: { width: 1 },
        minorTickLines: { width: 0 },
        labelFormat: '{value}',
    };

    const getData = async () => {
        const { data } = await axios.get(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/monthly`
        );
        setJan(data);
    };

    useEffect(() => {
        getData()
    }, [date])



    return (
        <div>
            <div>
                <p className='font-bold text-zinc-600'>Sales revenue vs target revenue | YTD</p>
                <div className='flex justify-center gap-1 m-5'>
                    <ChartComponent width={width} height={heigth} id="charts-big" primaryXAxis={stackedPrimaryXAxis} primaryYAxis={stackedPrimaryYAxis} chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} legendSettings={{ background: "white" }}>
                        <Inject services={[Legend, Category, StackingColumnSeries, Tooltip]} />
                        <SeriesCollectionDirective>
                            {stackedCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </div>
            </div>
        </div>
    )
}

export default SalesVsTarger