import React, { useState, useEffect } from 'react'
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, Inject, AccumulationSeriesDirective, AccumulationDataLabel,
    Legend, Category, Tooltip, DataLabel } from '@syncfusion/ej2-react-charts'
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';


const SalesGrowth = () => {

    const [growth, setGrowth] = useState([]);
    const { date } = useStateContext()

    const getData = async () => {
        let dates = new Date(date)
        const { data } = await axios.post(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/shipment-growth`,
            {date: dates}
        )
        setGrowth(data);
      };

    useEffect(() => {
        getData()
    }, [date])

    console.log(growth);

    const pieChartData = [
        { x: 'This year sales growth: ' + growth[1], y: growth[1], text: growth[1], color: 'red' },
        { x: 'Last year sales growth: ' + growth[0], y: growth[0], text: growth[0], color: 'blue' }
    ];
    const palettes = [
        '#9cce16',
        '#52525b'
    ];
    

    return (
        <div>
            <div>
                <p className='font-bold text-zinc-600'>Sales Growth</p>
                <div className='flex justify-center gap-1 m-7 mt-0 mb-0 '>
                    <AccumulationChartComponent id='line-chart' height='200px' width='400px'>
                        <Inject services={[AccumulationDataLabel, Legend, Tooltip, Category, DataLabel]} />
                        <AccumulationSeriesCollectionDirective>
                            <AccumulationSeriesDirective dataSource={pieChartData} xName='x' yName='y' startAngle='210' endAngle='150' dataLabel={{visible: true, position: 'Outside', name: 'x'}}>
                            </AccumulationSeriesDirective>
                        </AccumulationSeriesCollectionDirective>
                    </AccumulationChartComponent>
                </div>
                <div className='flex justify-center'>
                    <p className='font-bold text-zinc-600'>{growth[2]}% <span className={growth[2] < 0 ? 'text-red-500' : 'text-lime-500'}>{growth[2] > 0 ? <i class="fa-solid fa-arrow-up"></i> : <i class="fa-solid fa-arrow-down"></i>}</span></p>
                </div>
            </div>
        </div>
    )
}

export default SalesGrowth