import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Search, Inject, Toolbar, Sort } from '@syncfusion/ej2-react-grids'






const Customers = () => {

    const [customers, setCustomers] = useState([]);
    const { date } = useStateContext()

    const employeesGrid = [
    
        {
            field: 'Name',
            headerText: 'Name',
            width: '150',
            textAlign: 'Center',
        },
        {
            field: 'Revenue',
            headerText: 'Revenue',
            width: '170',
            textAlign: 'Center',
        },
    
        {
            field: 'GrowthRate',
            headerText: 'Growth Rate',
            width: '135',
            textAlign: 'Center'
        }
    ];
    
    const employeesData = [
    
    ]

    customers.forEach(e => {
        employeesData.push({EmployeeID: Math.random(), Name: e.name, Revenue: e.price + '$', GrowthRate: e.growth + '%' })
    });

    const getData = async () => {
        const { data } = await axios.get(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/top-ten-customers`
        );
        setCustomers(data);
    };

    useEffect(() => {
        getData()
    }, [date])

    return (
        /* customer */
        <div>
            <div>
                <p className='font-bold text-zinc-600'>Top 10 Customers per revenue</p>
                <div className='flex justify-center gap-1 m-5'>
                    <GridComponent dataSource={employeesData} allowSorting toolbar={['Search']} width="auto">
                        <ColumnsDirective>
                            {employeesGrid.map((item, index) => (
                                <ColumnDirective key={index} {...item} />
                            ))}
                        </ColumnsDirective>
                        <Inject services={[Sort, Search, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
        </div>
    )
}

export default Customers