import React from 'react'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useStateContext } from '../contexts/ContextProvider';

const Select = () => {

  const { date, setDate } = useStateContext()

  const change = () => {
    setDate(document.getElementById('datepicker').value)
  }

  return (
    <div>
      <DatePickerComponent id="datepicker" placeholder="Enter date" change={change}/>
    </div>
  )
}

export default Select