import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject,
    DataLabel, BarSeries, Category, Legend, Tooltip, ILoadedEventArgs, ChartTheme, Highlight
} from '@syncfusion/ej2-react-charts';
import { useStateContext } from '../contexts/ContextProvider';

const TotalSiteRevenue = () => {

    const [site, setSite] = useState([]);
    const { date } = useStateContext()

    const data1 = [];

    site.forEach(e => {
        data1.push({ x: e.name, y: e.price })
    });

    const getData = async () => {
        const { data } = await axios.get(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/sites`
        );
        setSite(data);
    };

    useEffect(() => {
        getData()
    }, [date])

    return (
        <div>
            <div>
                <p className='font-bold text-zinc-600'>Total Revenue Per Site</p>
                <div className='flex justify-center gap-1 m-7'>
                    <ChartComponent id='charts-bar' style={{ textAlign: "center" }} legendSettings={{ enableHighlight: true }} width="350px" height='350px'
                        primaryXAxis={{
                            valueType: 'Category',
                            majorGridLines: { width: 0 }
                        }}
                        primaryYAxis={{
                            labelFormat: '{value}$',
                            edgeLabelPlacement: 'Shift',
                            majorTickLines: { width: 0 },
                            lineStyle: { width: 0 },
                        }}
                        chartArea={{ border: { width: 0 } }}
                        tooltip={{ enable: true }}>
                        <Inject services={[BarSeries, DataLabel, Category, Legend, Tooltip, Highlight]} />
                        <SeriesCollectionDirective>
                            <SeriesDirective dataSource={data1} xName='x' yName='y' type='Bar' columnSpacing={0.1} name='Total sites revenue' width={2}>
                            </SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </div>
            </div>
        </div>
    )
}

export default TotalSiteRevenue