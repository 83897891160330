import React, {createContext, useContext, useState, useEffect} from 'react'
import axios from 'axios';

const StateContext = createContext()

const initialState = {

}


export const ContextProvider = ({children}) => {

    const [date, setDate] = useState(new Date().toLocaleDateString('en-US'))


    return (
        <StateContext.Provider value={{date, setDate}}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)