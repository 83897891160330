import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';

const OverallSalesData = () => {

    const [shipments, setShipments] = useState([]);
    const { date } = useStateContext()

    const getData = async () => {
        let dates = new Date(date)
        const { data } = await axios.post(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/shipments`,
            {date: dates}
        )
        setShipments(data);
      };

    useEffect(() => {
        getData()
    }, [date])
    


  return (
    <div>
        <div>
            <p className='font-bold text-zinc-600'>Sales Revenue | YTD</p>
            <div className='flex justify-center gap-1 m-7 mt-20 scale'>
                <div className='text-lime-500'>
                    <i class="fa-solid fa-chart-simple"></i>
                </div>
                <p className='text-zinc-600 text-4xl font-semibold'>{shipments}$</p>
            </div>
        </div>
    </div>
  )
}

export default OverallSalesData