import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject,
    Legend, Category, Tooltip, StackingColumnSeries
} from '@syncfusion/ej2-react-charts';

const RevenuePerProduct = () => {

    const [data, setData] = useState([]);
    const { date } = useStateContext()

    let e = 0
    let pr1 = 0
    let pr2 = 0
    let pr3 = 0
    let pr4 = 0
    data.forEach(element => {

        e += parseFloat(element.total_price)
        if(element.item_id == 1) {
            pr1 += parseFloat(element.total_price)
        } 
        if(element.item_id == 2) {
            pr2 += parseFloat(element.total_price)
        } 
        if(element.item_id == 4) {
            pr3 += parseFloat(element.total_price)
        } 
        if(element.item_id == 3) {
            pr4 += parseFloat(element.total_price)
        } 
    });
    
    console.log((pr4/e*100).toFixed());

    const stackedChartData = [
        [
            { x: data[0]?.name , y: pr1 }, { x: data[1]?.name, y: pr2 }, { x: data[4]?.name, y: pr4 }, { x: data[2]?.name, y: pr3 }
        ],
        [
            { x: data[0]?.name , y: (pr1/e*100).toFixed() }, { x: data[1]?.name, y: (pr2/e*100).toFixed() }, { x: data[4]?.name, y: (pr4/e*100).toFixed() }, { x: data[2]?.name, y: (pr3/e*100).toFixed() }
        ],
    ];

    const stackedCustomSeries = [

        {
            dataSource: stackedChartData[0],
            xName: 'x',
            yName: 'y',
            name: 'Item',
            type: 'StackingColumn',
            background: 'blue',

        },

        {
            dataSource: stackedChartData[1],
            xName: 'x',
            yName: 'y',
            name: 'Percentage',
            type: 'StackingColumn',
            background: 'red',

        },

    ];

    const stackedPrimaryXAxis = {
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        interval: 1,
        lineStyle: { width: 0 },
        labelIntersectAction: 'Rotate45',
        valueType: 'Category',
    };

    const stackedPrimaryYAxis = {
        lineStyle: { width: 0 },
        minimum: 0,
        maximum: 16000,
        interval: 5000,
        majorTickLines: { width: 0 },
        majorGridLines: { width: 1 },
        minorGridLines: { width: 1 },
        minorTickLines: { width: 0 },
        labelFormat: '{value}',
    };


    const getData = async () => {
        let dates = new Date(date)
        const { data } = await axios.post(
            `http://phpstack-654269-3176945.cloudwaysapps.com/api/product`,
            {date: dates}
        );
        setData(data);
    };

    useEffect(() => {
        getData()
    }, [date])

    console.log(data);

    
    return (
        <div>
            <div>
                <p className='font-bold text-zinc-600'>Revenue per Product</p>
                <div className='flex justify-center gap-1 m-7'>
                <ChartComponent  id="charts" primaryXAxis={stackedPrimaryXAxis} primaryYAxis={stackedPrimaryYAxis} width="350px" height='350px' chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} legendSettings={{ background: "white" }}>
                        <Inject services={[Legend, Category, StackingColumnSeries, Tooltip]} />
                        <SeriesCollectionDirective>
                            {stackedCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </div>
            </div>
        </div>
    )
}

export default RevenuePerProduct